import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MSAObject, Replay, RequestResponse } from 'src/app/core/domain';

export type ReplayCreateDto = Pick<Replay, 'Name' | 'Start' | 'SituationId' | 'InformationLayers' | 'ChunkParts'>;

@Injectable()
export class ReplayApiService {
  private readonly api_path = '/light/api/replay';

  constructor(private readonly http: HttpClient) {}

  create(replay: ReplayCreateDto): Observable<Replay> {
    return this.http.post(this.api_path, replay).pipe(map((res) => res as Replay));
  }

  getChunck(replay: Replay, chunk: number) {
    return this.http.get(`${this.api_path}/${replay._id}/${chunk}`).pipe(map((res) => res as MSAObject[]));
  }

  getHistory(replayId: string, msaobjectId: string) {
    return this.http.get<RequestResponse>(`${this.api_path}/${replayId}/history/${msaobjectId}`);
  }
}
