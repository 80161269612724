import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import {
  ChatPart,
  CpaObjectNotification,
  IDefaultParams,
  ITaskNotification,
  LanguageType,
  MSAObject,
  MSAObjectMap,
  Notification,
  NotificationObjectType,
  TranslationObjectType,
  User,
  VesselResponse,
} from '../domain';
import { ClusterService } from '../utils/cluster.service';
import { DebugCollectorService, ECategory } from '../utils/debug-collector.service';

export type SocketEventType =
  | 'connect'
  | 'reconnect_attempt'
  | 'disconnect'
  | 'reconnect'
  | 'logout'
  | 'end'
  | 'leave'
  | 'CpaObject'
  | 'unread-counter'
  | 'Counter'
  | 'Config'
  | 'MSAObjectUpdate'
  | 'Notification'
  | 'ping-socket-status-open-tab'
  | 'ping-socket-status-remove_tab'
  | 'ping-socket-status'
  | 'layerChange'
  | 'layerChange-unsubscribe'
  | 'subscribe'
  | 'unsubscribe'
  | 'Welcome'
  | 'UsersOnSocket'
  | 'loudSpeakerReceived'
  | 'automaticTranslation';

export type SocketWorkerMessage = { event: SocketEventType; data: any };

export type ConnectionState = 'on' | 'off';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private user?: User;
  public getUser() {
    return this.user;
  }

  //Variable to Configure the number if minutes to wait before page reload when connection is lost. Default 5minutes
  minutesToRefresh = 5 * 60000;

  private $connectionState: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>('off');
  connectionState$ = this.$connectionState.asObservable();

  private lastNotifiedState?: ConnectionState;
  private $connectionNotification: Subject<ConnectionState> = new Subject<ConnectionState>();
  connectionNotification$ = this.$connectionNotification.asObservable();

  private $isUnstableConnection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUnstableConnection$ = this.$isUnstableConnection.asObservable();

  refreshloader?: NodeJS.Timeout;

  public $sktEmitToServer: Subject<SocketWorkerMessage> = new Subject<SocketWorkerMessage>();
  public sktEmitToServer$ = this.$sktEmitToServer.asObservable();
  public $sktOnReceive: Subject<SocketWorkerMessage> = new Subject<SocketWorkerMessage>();
  public sktOnReceive$ = this.$sktOnReceive.asObservable();
  private socketOnReceiveEventFilter(event: SocketEventType, callback: (data: any) => void) {
    this.sktOnReceive$.pipe(filter((s: { event: string; data: any }) => s.event == event)).subscribe((s) => {
      callback(s.data);
    });
  }

  private $afterInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private afterInit$ = this.$afterInit.asObservable();
  public afterSocketInit() {
    return this.afterInit$.pipe(filter((v) => v)).pipe(map(() => undefined));
  }

  constructor(
    private readonly apiService: ApiService,
    private readonly dcs: DebugCollectorService,
    private readonly clusterService: ClusterService,
  ) {
    this.connectionState$.pipe(debounceTime(3000)).subscribe((state) => {
      if (this.lastNotifiedState || state == 'off') {
        this.$connectionNotification.next(state);
      }
      this.lastNotifiedState = state;

      if (state == 'off') {
        this.$isUnstableConnection.next(true);
      }
    });
  }

  setUser(user: User) {
    this.user = user;
    if (user !== undefined) {
      this.startSocket();
    }
  }

  public startSocket() {
    this.socketOnReceiveEventFilter('connect', () => {
      this.$connectionState.next('on');

      if (this.refreshloader) {
        clearTimeout(this.refreshloader);
      }

      let page = location.pathname.split('/')[1];
      const pages = location.pathname.split('/');

      if (page == 'home') page = 'dashboard';

      const pageIsHMI = !pages.includes('legacy') && !pages.includes('light');
      if (
        (pages.length === 2 && pages.includes('') && pageIsHMI && !pages.includes('data-source')) ||
        (pages.length === 3 && pages.includes('') && pageIsHMI && pages.includes('home'))
      ) {
        this.clusterService.deleteInitialCluster();
      }

      this.$sktEmitToServer.next({
        event: 'ping-socket-status-open-tab',
        data: {
          frontendType: 'hmi',
          page,
          ...(this.clusterService.getInitialCluster()
            ? {
              cluster: this.clusterService.getInitialCluster(),
            }
            : {}),
        },
      });

      this.dcs.log(['connected to server'], ECategory.SOCKET);
    });

    // on reconnect, use polling first and then websocket
    this.socketOnReceiveEventFilter('reconnect_attempt', () => {
      this.dcs.warn(['reconnect_attempt'], ECategory.SOCKET);
    });

    this.socketOnReceiveEventFilter('disconnect', async () => {
      this.$connectionState.next('off');

      await sleep(100);

      this.refreshloader = setTimeout(() => {
        location.reload();
      }, this.minutesToRefresh);

      this.dcs.error(['disconnected'], ECategory.SOCKET);
    });

    this.socketOnReceiveEventFilter('leave', function () {
      console.log('disconnecting from server(leave)');
    });

    this.socketOnReceiveEventFilter('logout', () => ({}));

    this.socketOnReceiveEventFilter('reconnect', () => {
      this.dcs.warn('you have been reconnected', ECategory.SOCKET);
    });

    this.socketOnReceiveEventFilter('Welcome', (message) => {
      this.dcs.log([message], ECategory.SOCKET);
      this.$afterInit.next(true);
    });
  }

  public emitRemoveTab() {
    const page = location.pathname.split('/')[2];

    this.$sktEmitToServer.next({
      event: 'ping-socket-status-remove_tab',
      data: {
        frontendType: 'hmi',
        page,
        ...(this.clusterService.getInitialCluster()
          ? {
            cluster: this.clusterService.getInitialCluster(),
          }
          : {}),
      },
    });
  }

  public emitGroupStatusListener(situationId: string) {
    this.$sktEmitToServer.next({
      event: 'ping-socket-status',
      data: { frontendType: 'hmi', activeSituations: situationId },
    });
  }

  public changedLayer(activeLayersId: string[]) {
    this.$sktEmitToServer.next({ event: 'layerChange', data: activeLayersId });
  }

  public pingAutomaticTranslation(objectType: TranslationObjectType, objectReference: string, language?: LanguageType) {
    this.$sktEmitToServer.next({ event: 'automaticTranslation', data: { objectType, objectReference, language } });
  }

  public receiveLoudSpeaker({ loudSpeakerId }: { loudSpeakerId: string }) {
    this.$sktEmitToServer.next({
      event: 'loudSpeakerReceived',
      data: { loudSpeakerId: loudSpeakerId },
    });
  }

  public changedLayerUnsubscribe(activeLayersId: string) {
    this.$sktEmitToServer.next({ event: 'layerChange-unsubscribe', data: activeLayersId });
  }

  disconnect() {
    this.$sktEmitToServer.next({ event: 'end', data: 'close connection' });
  }

  sendMsg(event: any, message?: any) {
    this.$sktEmitToServer.next({ event: event, data: message });
  }

  joinToRooms(data: {
    Layers?: string[];
    Chats?: string[];
    Situations?: string[];
    Events?: string[];
    Notifications?: boolean;
    Vessels?: string[];
    Ephemerals?: string[];
  }) {
    const rooms: any = {};
    rooms.Layers = data.Layers ? data.Layers : undefined;
    rooms.Chats = data.Chats ? data.Chats : undefined;
    rooms.Situations = data.Situations ? data.Situations : undefined;
    rooms.Events = data.Events ? data.Events : {};
    rooms.Notifications = data.Notifications ? data.Notifications : undefined;
    rooms.Vessels = data.Vessels ? data.Vessels : undefined;
    rooms.Ephemerals = data.Ephemerals ? data.Ephemerals : undefined;
    this.$sktEmitToServer.next({ event: 'subscribe', data: rooms });
  }

  leaveToRooms(data: {
    Layers?: string[];
    Chats?: string[];
    Situations?: string[];
    Events?: string[];
    Notifications?: boolean;
    Vessels?: string[];
    Ephemerals?: string[];
  }) {
    const rooms: any = {};
    rooms.Layers = data.Layers ? data.Layers : undefined;
    rooms.Chats = data.Chats ? data.Chats : undefined;
    rooms.Situations = data.Situations ? data.Situations : undefined;
    rooms.Events = data.Events ? data.Events : {};
    rooms.Notifications = data.Notifications ? data.Notifications : undefined;
    rooms.Vessels = data.Vessels ? data.Vessels : undefined;
    rooms.Ephemerals = data.Ephemerals ? data.Ephemerals : undefined;
    this.$sktEmitToServer.next({ event: 'unsubscribe', data: rooms });
  }

  getTimeline(): Observable<any> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Timeline'),
      map((s) => {
        return {
          Data: typeof s.data.data != 'string' ? JSON.stringify(s.data.data) : s.data.data,
          Type: s.data.type,
          Timestamp: s.data.timestamp,
        };
      }),
    );
  }

  getMessages(): Observable<ChatPart> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'chatMessage'),
      map((s) => s.data as ChatPart),
    ); //fromEvent(<any>this.socket, );
  }

  getMSAObjects(situationId?: string | (() => string)): Observable<MSAObjectMap[]> {
    return this.sktOnReceive$.pipe(
      filter((s) => s.event == 'MSAObjectUpdate'),
      map((o) => o.data),
      mergeMap((objects: MSAObject[]) => {
        const _situationId = typeof situationId === 'function' ? situationId() : situationId;

        const objectsWithExtendedData = objects?.map((o) => {
          if (_situationId && o.NeedUpdateExtendedData) {
            return this.apiService.msaObject.getMSAObject(o._id, o.Layer_id, _situationId).pipe(
              map((objectDetails: any) => {
                return {
                  ...objectDetails,
                  HumanActionType: o.HumanActionType,
                  HumanActionContext: o.HumanActionContext,
                  NeedUpdateExtendedData: true,
                };
              }),
            );
          }
          return of(o);
        });

        return forkJoin(objectsWithExtendedData);
      }),
      map((objects: MSAObject[]) => {
        return objects?.map((o) => {
          return { ...o, layerIds: [o.Layer_id] };
        });
      }),
      //share() //TODO: the extended data is requested for each subscriber, it should be requested only once and multicast for all subscribers
    );
  }

  getCpaObjects(): Observable<CpaObjectNotification> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'CpaObject'),
      map((s) => s.data as CpaObjectNotification),
    );
  }

  getNotifications(): Observable<Notification> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Notification'),
      map((s) => s.data as Notification),
    );
  }

  getMsaObjectUpdate(id: string): Observable<MSAObjectMap> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any[] }) => s.event === 'MSAObjectUpdate' && s.data.find(object => object._id === id)),
      map((s) => s.data.find(o => o._id === id) as MSAObjectMap),
    );
  }

  getAlerts(): Observable<Notification> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Alert'),
      map((s) => s.data as Notification),
    );
  }

  getVessels(): Observable<any> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Vessel'),
      map((s) => s.data as VesselResponse),
    );
  }

  getViewSites() {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'ViewSite'),
      map((s) => s.data as any),
    );
  }

  getVesselsContents(): Observable<any> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Contents'),
      map((s) => s.data),
    );
  }

  getConfig(): Observable<IDefaultParams> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Config'),
      map((s) => s.data as IDefaultParams),
    );
  }

  getCounter(): Observable<number> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Counter'),
      map((s) => s.data as number),
    );
  }

  logout(): Observable<any> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'logout'),
      map((s) => s.data),
    );
  }

  getUnreadCounter(): Observable<any> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == 'unread-counter'),
      map((s) => s.data),
    );
  }

  differentUser(id: string): boolean {
    return this.user?._id !== id;
  }

  getTodos(): Observable<ITaskNotification> {
    return this.sktOnReceive$.pipe(
      filter((s: { event: string; data: any }) => s.event == NotificationObjectType.Todo),
      map((s) => s.data as ITaskNotification),
    );
  }
}

function sleep(time: number): any {
  return new Promise((resolve) => setTimeout(resolve, time));
}
