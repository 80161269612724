import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { isEmpty, isNil } from 'lodash';
import { BehaviorSubject, Subscription, interval, timer } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import {
  BoardMessage,
  Group,
  Layer,
  LayerType,
  LightMSALevel,
  MSAObject,
  MSAObjectMap,
  NotificationActionType,
  RolePermission,
  SecurityEnumType,
  SecurityType,
  SensitivityLevel,
  SharingGroup,
  SharingGroupRule,
  SharingGroupRuleType,
  Situation,
  SituationEnvironmentEnum,
  SituationStateType,
  User,
  UserPermissions,
  UserSummary,
} from '../domain';
import { SocketService } from '../socket/socket.service';
import { AppCookiesService } from './app-cookies.service';
import { ClusterService } from './cluster.service';
import { GlobalUnitsService } from './global-units.service';
import { UsageService } from './usage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private user?: User;
  private userPermissions?: UserPermissions;
  private allPossiblesRolesNumbers?: { [propName: string]: number };
  private $sessionExpired = new BehaviorSubject<boolean>(false);
  public readonly sessionExpired$ = this.$sessionExpired.asObservable();

  timeBeforeRefresh = 5000;
  expirationTimerSubscription: Subscription;
  inactiveGroups: any;
  private timerSessionExpiration(): void {
    if (!this.expirationTimerSubscription || this.expirationTimerSubscription?.closed) {
      const timeoutCookie = this.cookieService.getSessionTimeoutCookie();
      const timeoutToken = timeoutCookie ? (jwt_decode(timeoutCookie) as any) : { expires: 6000 };
      const timeout = new Date(parseInt(timeoutToken.expires)).getTime() - new Date().getTime();

      this.expirationTimerSubscription = timer(timeout - this.timeBeforeRefresh).subscribe(async () => {
        // should refresh 5s before (timeBeforeRefresh) cookie expire
        const autoRefreshSession = this.user?.Preferences?.AutoRefreshSession;
        if (autoRefreshSession === true) {
          await this.refreshSession();
          return;
        }
        if (this.$sessionExpired.getValue() == false) {
          this.usageService.sync();
          this.$sessionExpired.next(true);
        }
      });
    }
  }

  private setTokenExpirationListener() {
    interval(1000).subscribe((_) => {
      this.timerSessionExpiration();

      const timeoutCookie = this.cookieService.getSessionTimeoutCookie();
      if (timeoutCookie) {
        const timeoutToken = jwt_decode(timeoutCookie) as any;
        const timeout = new Date(parseInt(timeoutToken.expires)).getTime() - new Date().getTime();
        if (timeout > 0) {
          if (timeoutToken.user !== this.user?.Email) {
            location.reload();
          }

          if (this.$sessionExpired.getValue() == true) {
            this.$sessionExpired.next(false);
          }
        }
      } else if (!timeoutCookie && this.$sessionExpired.getValue() === false) {
        this.$sessionExpired.next(true);
      }
    });
  }

  constructor(
    private apiService: ApiService,
    private socketService: SocketService,
    private cookieService: AppCookiesService,
    private usageService: UsageService,
    private globalUnitsService: GlobalUnitsService,
    private clusterService: ClusterService,
    private router: Router
  ) {
    this.onLogout();
    this.setTokenExpirationListener();
  }

  get isSessionExpired() {
    return this.$sessionExpired.getValue();
  }

  private heartbit() {
    interval(60000).subscribe((_) => {
        this.apiService.userGroup.heartbit('HMI').subscribe();
    })
    this.apiService.userGroup.heartbit('HMI').subscribe();
  }

  load = () =>
    new Promise<User>((resolve, reject) => {
      this.apiService.userGroup.getCurrentUser().subscribe(
        (user) => {
          this.socketService.setUser(user);
          this.user = user;

          if (user?.Group?.ActivityStatus === 'INACTIVE') {
            this.router.navigate(['denied'], { replaceUrl: true });
            return resolve(user);
          }

          this.heartbit()

          // giving time to app and classes intanciate
          setTimeout(() => {
            this.usageService.clearUsersData();
          }, 3000);

          this.setNotificationListeners();
          this.apiService.userGroup.getUserPermissions().subscribe((data) => {
            this.userPermissions = data.UserPermissions;
            this.allPossiblesRolesNumbers = data.AllPossiblesRolesNumbers;
            resolve(user);
          });
        },
        (error) => {
          window.location.href = `/light/api/login?appType=${this.isLightDomain() ? 'light' : 'hmi'}`;
          reject(error);
        },
      );
    });

  async refreshSession() {
    await this.apiService.userGroup.refreshSession(this.isLightDomain() ? 'light' : 'hmi');
  }

  async logout() {
    await this.usageService.sync();

    localStorage.removeItem('Language');
    localStorage.removeItem('Exercise');
    this.globalUnitsService.onDestroy();
    this.socketService.sendMsg('logout', this.user);
    if (location.pathname.indexOf('/dashboard') > -1) {
      window.location.href = `/light/api/logout?appType=${
        this.isLightDomain() ? 'light' : 'hmi'
      }&cluster=${this.clusterService.getInitialCluster()}`;
    } else window.close();
  }

  getUser(): User {
    return this.user!;
  }

  getUserPermissions() {
    return { ...this.userPermissions };
  }

  allowedSensitivity(): SensitivityLevel[] {
    if (!this.user || isNil(this.user.SensitivityLevel)) {
      return [];
    }
    const sensitivities = Object.keys(SensitivityLevel)
      .map((k) => k as keyof typeof SensitivityLevel)
      .filter((k) => isNaN(Number(k)))
      .map((k) => SensitivityLevel[k]);
    return sensitivities.slice(0, sensitivities.indexOf(this.user.SensitivityLevel) + 1);
  }

  allowedSecurity(): SecurityEnumType[] {
    if (!this.user) {
      return [];
    }
    const securities: SecurityEnumType[] = Object.keys(SecurityType)
      .filter((k) => isNaN(Number(k)))
      .map((k) => k as SecurityEnumType);

    const group = this.user.Group;

    if (!group) {
      return securities.filter((s) => s === SecurityType.International);
    }
    /* if (!group.Path || isEmpty(group.Path)) {
      return securities
        .filter(s => s === SecurityType.International || s === SecurityType.Local);
    }*/
    return securities
      .filter((s) => s !== SecurityType.Interregional || !!group?.Path?.Interregional)
      .filter((s) => s !== SecurityType.Region || !!group?.Path?.RegionName)
      .filter((s) => s !== SecurityType.Zone || !!group?.Path?.ZoneName)
      .filter(
        (s) =>
          s !== SecurityType.National ||
          (isEmpty(group.Path) && !!group.CountryCode) ||
          (!!group?.Path?.ZoneName && !group.Children?.includes(group?.Path?.ZoneName)),
      );
  }

  hasRolePermissionEqual(rolePermissionRequired: RolePermission): boolean {
    if (!this.user) {
      return false;
    }

    return this.allPossiblesRolesNumbers![rolePermissionRequired] == this.user.Role;
  }

  hasRolePermissionEqualOrAbove(rolePermissionRequired: RolePermission): boolean {
    if (!this.user) {
      return false;
    }

    return this.allPossiblesRolesNumbers![rolePermissionRequired] >= this.user.Role;
  }

  hasRolePermissionEqualOrAboveToShipDatabase(): boolean {
    return this.hasRolePermissionEqualOrAbove(RolePermission.operator);
  }

  hasRolePermissionForAuth(): boolean {
    if (!this.user) {
      return false;
    }

    let result: boolean;
    if (this.user?.Roles?.length)
      result = this.user.Roles?.some((role) => this.allPossiblesRolesNumbers[role] !== undefined);

    return result;
  }

  hasJudicialExportRolePermission(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles?.includes(RolePermission.judicial_expert);
  }

  getRoleForUser(user: User): RolePermission | undefined {
    if (!this.allPossiblesRolesNumbers && !user?.Roles.length) {
      return undefined;
    }

    let greatestRole: RolePermission | undefined = undefined;
    user.Roles.forEach((role) => {
      if (
        greatestRole === undefined ||
        this.allPossiblesRolesNumbers![role] > this.allPossiblesRolesNumbers![greatestRole]
      )
        greatestRole = RolePermission[role];
    });

    if (!greatestRole) {
      return (user.Roles as unknown as RolePermission | 'center_coordenator')?.includes('center_coordenator')
        ? RolePermission.local_administrator
        : greatestRole;
    }

    return greatestRole;
  }

  canAccessAnalytics() {
    if (!this.user) {
      return false;
    }

    const roles: any = this.user.Roles;

    return (
      !roles.includes(RolePermission.judicial_expert) &&
      (this.isITAdmin() ||
        roles?.includes('analytics_manager') ||
        roles?.includes('analytics_user') ||
        roles?.includes('analytics_administrator'))
    );
  }

  isAdmin() {
    return this.isSystemAdmin() || this.isITAdmin();
  }

  isITAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('it_administrator');
  }

  isOperator(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('operator');
  }

  isAuthority(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('authority');
  }

  isSystemAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('system_administrator');
  }

  isYaonde(path?: any): boolean {
    if (!this.user) {
      return false;
    }
    if (path) return path.Interregional === 'icc';
    return this.user.Group.Path?.Interregional === 'icc';
  }

  isAfricanStakeholder(): boolean {
    if (!this.user) {
      return false;
    }

    return !!this.user?.Group?.DirectoryPath?.includes('AS - African_Stakeholders');
  }

  isSameGroup(user: User) {
    return user.GroupId === this.getUser().GroupId;
  }

  isOwner(info: { CreatedBy?: UserSummary; Owner?: string }): boolean {
    if (!this.user) {
      return false;
    }
    if (this.user.GroupName === info.Owner || info.Owner?.includes(this.user.GroupName)) {
      return true;
    }
    return false;
  }

  isAlwaysSharedWith(info: { SharingGroups: SharingGroup[] }, group?: Group): boolean {
    if (!this.user) {
      return false;
    }

    if (info?.SharingGroups?.length) {
      const groupToGetSharing = group?._id ? { GroupId: group._id } : undefined;
      const sharingGroup = this.getGroupFromSharingGroupList(info, groupToGetSharing);
      return !!sharingGroup?.AlwaysShared;
    }

    return false;
  }

  isLightMSA(): boolean {
    if (!this.user) {
      return false;
    }
    if (!this.user.Preferences) {
      return false;
    }
    return this.user.Preferences?.LightMSA !== LightMSALevel.OFF;
  }

  isLightMSALow(): boolean {
    return this.isLightMSA() && this.user?.Preferences?.LightMSA === LightMSALevel.LOW;
  }

  isLightMSAMedium(): boolean {
    return this.isLightMSA() && (this.user?.Preferences?.LightMSA === LightMSALevel.MEDIUM || this.isLightMSALow());
  }

  isLightMSAHigh(): boolean {
    return this.isLightMSA() && (this.user?.Preferences?.LightMSA === LightMSALevel.HIGH || this.isLightMSAMedium());
  }

  hasVideoStream(): boolean {
    const preferences: LightMSALevel = this.user?.Preferences?.LightMSA;
    return preferences === LightMSALevel.HIGH || preferences === LightMSALevel.OFF;
  }

  isLightDomain(): boolean {
    const origin = window.location.origin;
    const absoluteRoute = window.location.href;
    const base = absoluteRoute.replace(origin, '');
    return base.startsWith('/light');
  }

  isUserFromGroup(group: Group) {
    return this.user && this.user.GroupId === group._id;
  }

  isLayerReleasabledOutYa(layer: Layer): boolean {
    return (
      layer.Security !== SecurityType.Local &&
      layer.Security !== SecurityType.National &&
      layer.Security !== SecurityType.International
    );
  }

  /********************************************************************/
  /************************* PERMISSIONS ******************************/
  /********************************************************************/

  getGroupFromSharingGroupList(
    context: { SharingGroups: SharingGroup[] },
    owner?: { GroupId: string },
  ): SharingGroup | undefined {
    const _owner = owner || this.user;
    return context.SharingGroups?.find((g) => _owner?.GroupId && g.GroupId === _owner?.GroupId);
  }

  getGroupFromSharingGroupsAndRules(
    context: { SharingGroups: SharingGroup[]; SharingGroupRules: SharingGroupRule[] },
    groupId?: string,
  ): SharingGroup | undefined {
    const _groupId = groupId || this.user?.GroupId;
    const anyoneGroup = context?.SharingGroupRules?.find((g) => g.GroupName === SharingGroupRuleType.Anyone);
    const nationalGroup = context?.SharingGroupRules?.find(
      (g) =>
        this.user.Group?.CountryCode &&
        g.RuleType === SharingGroupRuleType.National &&
        g.CountryCodes?.includes(this.user.Group?.CountryCode),
    );
    const userRule = context?.SharingGroupRules?.find(
      (g) => g.RuleType === SharingGroupRuleType.User && g.UserId === this.user._id,
    );
    const sharingGroup = context?.SharingGroups?.find((g) => g.GroupId === _groupId);

    if ((!sharingGroup && !anyoneGroup && !nationalGroup && !userRule) || !this.user) {
      return;
    }

    return {
      GroupName: sharingGroup?.GroupName || this.user?.GroupName,
      GroupId: sharingGroup?.GroupId || this.user?.GroupId,
      CanDelete: !!(
        sharingGroup?.CanDelete ||
        nationalGroup?.CanDelete ||
        anyoneGroup?.CanDelete ||
        userRule?.CanDelete
      ),
      CanWrite: !!(sharingGroup?.CanWrite || nationalGroup?.CanWrite || anyoneGroup?.CanWrite || userRule?.CanWrite),
      CanShare: !!(sharingGroup?.CanShare || nationalGroup?.CanShare || anyoneGroup?.CanShare || userRule?.CanShare),
    };
  }

  /**
   * Verify the access rights to the Log Events app.
   * Write permissions allow the user to create new events.
   */
  getSituationAccessRights(
    info: Situation,
    ignoreChangeSituationRolePermission = false,
  ): { write: boolean; share: boolean; delete: boolean } | undefined {
    if (!this.user) {
      return;
    }
    if (!info) {
      return { write: false, share: false, delete: false };
    }

    const notLocal: boolean = info.Security !== SecurityType.Local;

    if (info.State?.Type == SituationStateType.Draft) {
      const permissions = info.State?.SharingGroupRules?.find((r) => r.UserId == this.user._id);

      return { write: !!permissions, share: !!permissions?.CanShare, delete: !!permissions?.CanDelete };
    }

    if (info.Owner === this.user.GroupName) {
      return {
        write: ignoreChangeSituationRolePermission || !!this.userPermissions?.MSA?.Change_Situation,
        share: !!(info?.IsActive && notLocal && this.userPermissions?.MSA?.Share_Situation),
        delete: false,
      };
    } else {
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return { write: false, share: false, delete: false };
      }

      return {
        write: !!(
          group.CanWrite &&
          (ignoreChangeSituationRolePermission || this.userPermissions?.MSA?.Change_Situation)
        ),
        share: !!(group.CanShare && notLocal && this.userPermissions?.MSA?.Share_Situation),
        delete: false,
      };
    }
  }

  /**
   * Verify the access rights to the Log Events app.
   * Write permissions allow the user to create new events.
   */
  getLogEventsAccessRights(info: Situation): { write: boolean } | undefined {
    if (!this.user) {
      return;
    }
    if (!info) {
      return { write: false };
    }
    if (!info.IsActive) {
      return { write: false };
    }
    if (
      info.IsDefault &&
      (this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator))
    ) {
      return { write: true };
    }

    if (info.Owner === this.user.GroupName) {
      // Rules for all the users belonging to the situation owner center
      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator)
      ) {
        return { write: true };
      }
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return;
      }

      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator)
      ) {
        return { write: !!group.CanWrite };
      }
    }

    return { write: false };
  }

  /**
   * Verify the access rights to the Comments app.
   * Write permissions allow the user to create new comments.
   */
  getLogEventsCommentsAccessRights(info: Situation): { write: boolean } | undefined {
    return this.getLogEventsAccessRights(info);
  }

  /**
   * Verify the access rights to the Chat app.
   * Write permissions allow the user to send new messages in the chat.
   */
  getChatAccessRights(info: Situation): { write: boolean } | undefined {
    if (!this.user) {
      return;
    }
    if (!info) {
      return { write: false };
    }
    if (!info.IsActive) {
      return { write: false };
    }
    if (info.IsDefault) {
      return { write: true };
    }

    if (info.Owner === this.user.GroupName) {
      // Rules for all the users belonging to the situation owner center
      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.guest)
      ) {
        return { write: true };
      }
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return;
      }

      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.guest)
      ) {
        return { write: !!group?.CanWrite };
      }
    }

    return { write: false };
  }

  /**
   * Verify the access rights to situations in the MSA app.
   * Write permissions allow the user to add a layer to a situation.
   * Delete permissions allow the user to remove a layer from the situation.
   */
  getMSASituationAccessRights(situation: Situation, layer: Layer): { write: boolean; delete: boolean } | undefined {
    if (!this.user) {
      return;
    }
    if (!situation) {
      return { write: false, delete: false };
    }
    if (!layer) {
      return { write: false, delete: false };
    }

    if (!situation.IsActive) {
      return { write: false, delete: false };
    }
    if (situation.IsDefault) {
      return { write: true, delete: true };
    }

    let removeLayer = false;

    const situationAccess = this.getSituationAccessRights(situation, true);
    const canRemoveFromSituation = this.isOwner(situation);

    if (this.isOwner(layer)) {
      removeLayer = true;
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(layer);
      if (!group) {
        return;
      }
    }

    return { write: !!situationAccess?.write, delete: canRemoveFromSituation || removeLayer };
  }

  /**
   * Verify the access rights to the Layers in the MSA app.
   * Write permissions allow the user to add a layer to a situation.
   * Delete permissions allow the user to remove a layer from the situation.
   */
  getMSALayerAccessRights(info: Layer):
    | {
        modify: {
          write?: boolean;
          delete?: boolean;
          share?: boolean;
        };
        access: {
          Add_To_Situation_Layer?: boolean;
          Create_Layer?: boolean;
          Edit_Layer?: boolean;
          Duplicate_Layer?: boolean;
          Share_Layer?: boolean;
          Delete_Layer?: boolean;
          Remove_From_Situation_Layer?: boolean;
        };
      }
    | undefined {
    if (!this.user) {
      return;
    }

    const modify_permissions = {
      Add_To_Situation_Layer: this.userPermissions?.Layer?.Add_To_Situation_Layer,
      Create_Layer: this.userPermissions?.Layer?.Create_Layer,
      Edit_Layer: this.userPermissions?.Layer?.Edit_Layer,
      Duplicate_Layer:
        this.userPermissions?.Layer?.Duplicate_Layer && !(info.IsDefault && info.LayerType == LayerType.View),
      Share_Layer: this.userPermissions?.Layer?.Share_Layer,
      Delete_Layer: this.userPermissions?.Layer?.Delete_Layer,
      Remove_From_Situation_Layer: this.userPermissions?.Layer?.Remove_From_Situation_Layer,
    };
    if (!info) {
      return { modify: { write: false, share: false, delete: false }, access: modify_permissions };
    }

    const notLocal: boolean = info.Security !== SecurityType.Local;

    const group = this.getGroupFromSharingGroupsAndRules(info);
    if (this.isOwner(info)) {
      return {
        modify: {
          write: modify_permissions.Create_Layer,
          share: modify_permissions.Share_Layer && notLocal,
          delete: modify_permissions.Delete_Layer,
        },
        access: modify_permissions,
      };
    } else {
      if (!group) {
        return;
      }

      return {
        modify: { write: group.CanWrite, share: group.CanShare && notLocal, delete: false },
        access: modify_permissions,
      };
    }
  }

  getMSAObjectAccessRights(msaObject: MSAObject | MSAObjectMap, layer: Layer) {
    let isMSAObjectLayer = false;
    if ((msaObject as MSAObject).Layer_id) {
      isMSAObjectLayer = (msaObject as MSAObject)?.Layer_id === layer?._id;
    } else {
      isMSAObjectLayer = (msaObject as MSAObjectMap).layerIds?.includes(layer?._id);
    }

    if (!isMSAObjectLayer) return null;

    const layerPermisions = this.getMSALayerAccessRights(layer);
    const canEdit = !!layerPermisions?.modify?.write && this.getUserPermissions()?.Layer?.Manage_Msaobjects_Layer;
    const canDelete = canEdit && layer.LayerType === LayerType.Points;
    return { modify: { write: canEdit, delete: canDelete } };
  }

  canCreateBoardMessage(): boolean {
    return (
      this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
      this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
      this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)
    );
  }

  canEditBoardMessage(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForWriteOrDeletePermission(message)
    );
  }

  canDeleteBoardMessage(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForWriteOrDeletePermission(message)
    );
  }

  canEditBoardMessageSharePermissions(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForSharePermission(message)
    );
  }

  checkForWriteOrDeletePermission(message: BoardMessage): boolean {
    const anyoneCanWriteOrDelete: boolean = !!message?.SharingGroupRules.find((sgr) => sgr.GroupName === 'Anyone')
      ?.CanWrite;
    const userGroup = message?.SharingGroups.find((sg) => sg.GroupName === this.user?.GroupName);
    return !!(anyoneCanWriteOrDelete || userGroup?.CanWrite);
  }

  checkForSharePermission(message: BoardMessage): boolean {
    const anyoneCanShare: boolean = !!message?.SharingGroupRules.find((sgr) => sgr.GroupName === 'Anyone')?.CanShare;
    const userGroup = message?.SharingGroups.find((sg) => sg.GroupName === this.user?.GroupName);
    return !!(anyoneCanShare || userGroup?.CanShare);
  }

  canAddToLayerLibrary(): boolean {
    return this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager);
  }

  canRemoveFromLayerLibrary(): boolean {
    return this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager);
  }

  /********************************************************************/
  /************************* END PERMISSIONS **************************/
  /********************************************************************/

  private setNotificationListeners(): void {
    const notifications = this.socketService.getNotifications();

    notifications
      // TODO: why doesn't the NotificationObjectType.User work?
      .pipe(filter((n) => n.ObjectType === 'User'))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(pluck('ObjectInfo'))
      .subscribe((user: User) => {
        if (this.user?.Preferences.LightMSA != user.Preferences.LightMSA) {
          location.reload();
        }
        this.user = { ...user, Preferences: user.Preferences, Group: { ...user.Group, Path: user.Group.Path || {} } };
      });
  }

  private onLogout() {
    this.socketService.logout().subscribe((logout) => {
      if (this.getUser().Username === logout.Username || this.getUser().ExternalId === logout.ExternalId) {
        if (
          location.pathname.indexOf('/home') > -1 ||
          location.pathname.indexOf('/dashboard') > -1 ||
          location.pathname.indexOf('/denied') > -1
        ) {
          window.location.href = `/light/api/logout?appType=${this.isLightDomain() ? 'light' : 'hmi'}`;
        } else window.close();
      }
    });
  }

  getSituationEnviroment(): SituationEnvironmentEnum {
    return this.cookieService.getSituationEnviroment();
  }

  userHaveAntecipatedAccessToBoardMessage(message: BoardMessage) {
    return (
      (this.canEditBoardMessage(message) || this.canEditBoardMessageSharePermissions(message)) &&
      message.StartsAt >= new Date().toISOString()
    );
  }
}
