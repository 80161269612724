import { VesselConfigType } from './map.domain';
import { User, UserSummary } from './user-group.domain';

export enum FromBandwidth {
  ShipDatabase = 'ship-database',
}

export enum NotificationObjectType {
  Situation = 'Situation',
  Workbench = 'Workbench',
  Chat = 'Chat',
  Event = 'Event',
  Comment = 'Comment',
  Layer = 'Layer',
  MSAObject = 'MSAObject',
  CenterState = 'CenterState',
  User = 'User',
  Folder = 'Folder',
  ChatPart = 'ChatPart',
  CpaObject = 'CpaObject',
  BoardMessage = 'BoardMessage',
  LoudSpeaker = 'LoudSpeaker',
  Todo = 'Todo',
  UserMapConfig = 'UserMapConfig',
  Incident = 'Incident',
}

export enum NotificationActionType {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted',
  Added = 'Add',
  Deactivate = 'Deactivate',
  Add = 'Status',
  Remove = 'Remove',
  LostAccess = 'LostAccess',
  GainAccess = 'GainAccess',
  Published = 'Published',
  Trespass = 'Trespass',
  Expired = 'Expired',
}

export enum SensitivityLevel {
  Public = 0,
  Private = 1,
  Secret = 2,
}

export type SensitivityLevelType = keyof typeof SensitivityLevel;

export enum SecurityType {
  Local = 'Local',
  National = 'National',
  Zone = 'Zone',
  Region = 'Region',
  Interregional = 'Interregional',
  International = 'International',
}

export enum BroadcastChannelType {
  ViewOnMap = 'ViewOnMap',
  PageStatus = 'PageStatus',
  Language = 'Language',
}

export type SecurityEnumType = keyof typeof SecurityType;

export interface SharingGroup {
  GroupName?: string;
  GroupId: string;
  GroupDescription?: string;
  CountryCode?: string;
  CanWrite?: boolean;
  CanDelete?: boolean;
  CanShare?: boolean;
  IsActive?: boolean;
  AlwaysShared?: boolean;
  Disabled?: boolean;
  Shared?: boolean;
}

export interface SharingGroupRule {
  RuleType?: SharingGroupRuleType;
  GroupName?: string;
  CanWrite?: boolean;
  CanDelete?: boolean;
  CanShare?: boolean;
  UserId?: string;
  AlwaysShared?: boolean;
  CountryCodes?: string[];
}

export interface ToggleSharingLayers {
  LayerId: string;
  ToggleChecked: boolean;
}

export interface Content {
  _id: string;
  Filename: string;
  CreatedAt: Date;
  CreatedBy?: UserSummary;
  Sensitivity: SensitivityLevel;
  Security: SecurityType;
  Source: ContentSource;
  ParentName: string;
  Message?: string;
  StatusOfBackgroundProcessings: { [key: string]: BackgroundProcessingStatus };
  Size?: number;
}

export enum PermissionType {
  Situation,
  Event,
  Comment,
  Layer,
  MSAObject,
}

export enum ContentSource {
  Chat = 'Chat',
  Event = 'Event',
  Layer = 'Layer',
  Report = 'Report',
  Vessel = 'Vessel',
  Inspection = 'Inspection',
  MsaObject = 'MsaObject',
}

export interface RequestResponse<T = any> {
  [x: string]: any;
  type: string;
  error: any;
  data: T;
  total: number;
  limit: number;
  page: number;
  pages: number;
  offset: number;
}

export interface RequestListResponse<T = any> {
  [x: string]: any;
  type: string;
  error: any;
  docs?: T[]; // TODO: IMPROVE
  data: {
    docs: T[];
  };
  total: number;
  limit: number;
  page: number;
  pages: number;
  offset: number;
}

export interface IPaginatedRequest {
  limit?: number;
  offset?: number;
}

export interface BackgroundProcessingStatusData {
  processorName: string;
  processingStatus: BackgroundProcessingStatus;
  data: any;
}

export interface BackgroundProcessingStatus {
  status: ItemBackgroundProcessingStatusType;
}

export enum ItemBackgroundProcessingStatusType {
  Pending = 'Pending',
  Processing = 'Processing',
  Done = 'Done',
  Error = 'Error',
}

export enum RolePermission {
  judicial_expert = 'judicial_expert',
  guest = 'guest',
  authority = 'authority',
  operator = 'operator',
  supervisor = 'supervisor',
  decidor = 'decidor',
  local_administrator = 'local_administrator',
  operations_manager = 'operations_manager',
  system_administrator = 'system_administrator',
  it_administrator = 'it_administrator',
  center_coordenator = 'center_coordenator',
}

export type RolePermissionEnumType = keyof typeof RolePermission;

export enum Language {
  en = 'en',
  es = 'es',
  fr = 'fr',
  pt = 'pt',
}

export enum LightMSALevel {
  OFF = 'OFF',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum CoordinatesFormat {
  DMS = 'DMS',
  DMm = 'DM.m',
  Ddd = 'D.dd',
}

export enum DistanceUnit {
  Km = 'Km',
  Nm = 'Nm',
}

export interface Icons {
  _id: string;
  Name: string;
  IconURL: string;
  Description?: string;
  Visible: boolean;
  CreatedAt: Date;
  CreatedBy: string;
  GroupName: string;
}

export enum BackgroundProcessor {
  ThumbnailPostProcessor = 'ThumbnailPostProcessor',
  GeometryFileProcessor = 'GeometryFileProcessor',
}

export enum SharingGroupRuleType {
  Anyone = 'Anyone',
  National = 'National',
  User = 'User',
}

export enum DirexAlertType {
  Standard = 'Standard',
  Important = 'Important',
  Critical = 'Critical',
}

export type DirexAlertEnumType = keyof typeof DirexAlertType;

export interface Tags {
  _id: string;
  Name: string;
  Description?: string;
  Situation_id?: string;
  Visible: boolean;
  Type: string;
  Used: boolean;
}

export interface Notification {
  _id: string;
  ObjectType: NotificationObjectType;
  ObjectReference: string;
  ActionType: NotificationActionType;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  Timestamp?: string;
  CreatedBy?: string | User;
  SharedWith: string;
  Situation_id?: string;
  Event_id?: string;
  ObjectInfo?: any;
  extraInfo?: { generatedBy?: string };
  IsSharing: boolean;
  Trespasser?: any;
  SharingGroups?: SharingGroup[];
  SharingGroupRules?: SharingGroupRule[];
}

export enum NotificationFilter {
  All = 'All',
  Hidden = 'Hidden',
  NotHidden = 'NotHidden',
}

export enum ReadableType {
  Event = 'Event',
  Comment = 'Comment',
  ChatPart = 'ChatPart',
}

export type BandwidthLatencyType = {
  Best?: number;
  Good?: number;
  Medium?: number;
  Low?: number;
};

export interface FeatureConfig {
  [key: string]: boolean;
}

export interface DebugFlagConfig {
  [key: string]: boolean;
}

export interface IStreaming {
  isActive: boolean;
  maxStreams: number;
}

export interface IAudio {
  AudioRecordingEnabled: boolean;
  AudioRecordingTimeout: number;
  SpeechRecognitionEnabled: boolean;
  SpeechRecognitionContinuous: boolean;
  SpeechRecognitionInterimResults: boolean;
}

export interface IDefaultParams {
  IncidentRadius: number;
  IncidentEmailSubject: string;
  IncidentEmailCC: string;
  IncidentEmailUsername: string;
  VesselPhotoByMMSIProviderLink?: string;
  VesselPhotoByIMONumberProviderLink?: string;
  CpaMaxDurationInHours: number;
  HistoryTTL: number;
  InactiveMSATime: number;
  RefreshTimeToken: number;
  LogoURL: string;
  BandwidthLatency?: BandwidthLatencyType;
  ExerciseTag: string;
  MSADefaults?: {
    OFF?: any;
    LOW?: any;
    MEDIUM?: any;
    HIGH?: any;
  };
  EventsOnlineTTL: number;
  MaxDynamicCircleRadiusNM: number;
  SKYLIGHT_QUERYINTERVAL: number;
  SKYLIGHT_TTL: number;
  SKYLIGHT_SYNCCRON: string;
  SKYLIGHT_URL: string;
  SKYLIGHT_USERNAME: string;
  SKYLIGHT_PASSWORD: string;
  SKYLIGHT_AOI_ID: string;
  SkylightShowEventLink: boolean;
  TurfTolerance: number;
  MapboxTolerance: number;
  ShowInMapAvailableOptions: VesselConfigType[];
  AllowedRoles: string[];
  AppVersion: string;
  Environment: string;
  FeatureFlag: FeatureConfig;
  DebugFlag: DebugFlagConfig;
  Debug: Debug;
  Streaming: IStreaming;
  ShowLayerLibraryInLayerOptions?: boolean;
  CanEditFoldersInLayerLibrary?: boolean;
  Audio?: IAudio;
  EnableTitleErrorWarn?: boolean;
  EnableSituationDocumentsMenu?: boolean;
}

export interface Debug {
  isActive: boolean;
  isProtected: boolean;
  password: string;
}

export interface Folder {
  _id: string;
  Name: string;
  Owner: string;
  Type: FolderType;
  Parent: string;
}

export enum FolderType {
  Situation = 'Situation',
  LayerView = 'LayerView',
  LayerInformation = 'LayerInformation',
  LayerSmart = 'LayerSmart',
  LayerThirdParty = 'LayerThirdParty',
  LayerLibrary = 'LayerLibrary',
  YaondeLayers = 'YaondeLayers',
}

export interface FolderGroup {
  Folder: string;
  GroupName: string;
  FolderType: FolderType;
}

export interface FolderSummary {
  Items: number;
  Folders: number;
  DefaultItems: number;
  cannotBeDeletedItems: number;
}

export enum EnvironmentsLongName {
  development = 'Development',
  integration = 'Integration',
  operations = 'OPS/TRN',
  training = 'EDUC/DEMO',
}

export interface UnreadCountSummary {
  UnreadCount: number;
  ReadableParentId: string;
}

export interface UnreadCountSummaryByTab {
  ReadableParentId: string;
  UnreadCountByTab: {
    All: number;
    Favorites: number;
    System: number;
    Hidden: number;
  };
}
export interface DirexLoudSpeaker {
  _id: string;
  Title: string;
  Description: string;
  AlertType: DirexAlertType;
  CreatedAt?: Date;
  CreatedBy?: { _id: string; Username: string };
  ReceivedUsers?: string[];
  ReadConfirmationUsers?: string[];
}

export type OWSLayer = {
  title: string;
  description?: string;
  url: string;
};

export type ApiKeyParentType = 'situation' | 'center_library' | 'layers_library'
export type ApiKeyType = 'geoserver_access';

export type ApiKeyEntityType = {
  key: string;

  type: ApiKeyType;

  parentType: ApiKeyParentType;

  parentId: string;
};
