import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { User } from '../domain';
import { FeatureFlags, FeatureFlagsService } from './feature-flag.service';

export type SpeedUnitType = 'knots' | 'kmh';
export type DistanceUnitType = 'nm' | 'meter' | 'miles' | 'km';

@Injectable({
  providedIn: 'root',
})
export class GlobalUnitsService {
  private readonly SPEED_UNIT_STORAGE_KEY = 'speedUnit';
  private readonly DISTANCE_UNIT_STORAGE_KEY = 'distanceUnit';
  private readonly DEFAULT_SPEED_UNIT = 'knots';
  private readonly DEFAULT_DISTANCE_UNIT = 'nm';

  private $speedUnit: BehaviorSubject<SpeedUnitType> = new BehaviorSubject<SpeedUnitType>(this.DEFAULT_SPEED_UNIT);
  private $distanceUnit: BehaviorSubject<DistanceUnitType> = new BehaviorSubject<DistanceUnitType>(
    this.DEFAULT_DISTANCE_UNIT,
  );

  constructor(private featureFlagsService: FeatureFlagsService) {}

  init(user?: User) {
    if (window.localStorage.getItem(this.DISTANCE_UNIT_STORAGE_KEY)) {
      const distance = window.localStorage.getItem(this.DISTANCE_UNIT_STORAGE_KEY) as DistanceUnitType;
      this.setDistanceUnit(distance);
    } else if (user?.Preferences?.DistanceUnit) {
      const distance = user?.Preferences?.DistanceUnit.toLowerCase() as DistanceUnitType;
      this.setDistanceUnit(distance);
    } else {
      this.setDistanceUnit(this.DEFAULT_DISTANCE_UNIT);
    }
    if (window.localStorage.getItem(this.SPEED_UNIT_STORAGE_KEY)) {
      const speed = window.localStorage.getItem(this.SPEED_UNIT_STORAGE_KEY) as SpeedUnitType;
      this.setSpeedUnit(speed);
    } else if (user?.Preferences?.SpeedUnit) {
      const speed = user?.Preferences?.SpeedUnit.toLowerCase() as SpeedUnitType;
      this.setSpeedUnit(speed);
    } else {
      this.setSpeedUnit(this.DEFAULT_SPEED_UNIT);
    }
  }

  get speedUnit(): SpeedUnitType {
    return this.$speedUnit.getValue();
  }

  getSpeedUnit$(): Observable<SpeedUnitType> {
    return this.$speedUnit
      .asObservable()
      .pipe(filter(() => this.featureFlagsService.isFeatureEnabled(FeatureFlags.ChangeMetricUnits)));
  }

  setSpeedUnit(value: SpeedUnitType) {
    this.$speedUnit.next(value);
    this.saveSpeedUnitToLocalStorage(value);
  }

  get distanceUnit(): DistanceUnitType {
    return this.$distanceUnit.getValue();
  }

  getDistanceUnit$(): Observable<DistanceUnitType> {
    return this.$distanceUnit
      .asObservable()
      .pipe(filter(() => this.featureFlagsService.isFeatureEnabled(FeatureFlags.ChangeMetricUnits)));
  }

  setDistanceUnit(value: DistanceUnitType) {
    this.$distanceUnit.next(value);
    this.saveDistanceUnitToLocalStorage(value);
  }

  private saveSpeedUnitToLocalStorage(speedUnit: SpeedUnitType) {
    localStorage.setItem(this.SPEED_UNIT_STORAGE_KEY, speedUnit);
  }

  private saveDistanceUnitToLocalStorage(distanceUnit: DistanceUnitType) {
    localStorage.setItem(this.DISTANCE_UNIT_STORAGE_KEY, distanceUnit);
  }

  onDestroy() {
    this.$speedUnit.complete();
    this.$distanceUnit.complete();
    localStorage.removeItem(this.SPEED_UNIT_STORAGE_KEY);
    localStorage.removeItem(this.DISTANCE_UNIT_STORAGE_KEY);
  }

  speedOptions: { label: string; value: SpeedUnitType }[] = [
    { label: 'Knts', value: 'knots' },
    { label: 'Km/h', value: 'kmh' },
  ];

  distanceOptions: { label: string; value: DistanceUnitType }[] = [
    { label: 'NM', value: 'nm' },
    { label: 'M', value: 'meter' },
    { label: 'MI', value: 'miles' },
    { label: 'KM', value: 'km' },
  ];

  getInitialDistanceUnitOpt(): { label: string; value: DistanceUnitType } {
    if (this.featureFlagsService.isFeatureEnabled(FeatureFlags.ChangeMetricUnits)) {
      return this.distanceOptions.find((unit) => unit.value === this.distanceUnit);
    } else {
      return this.distanceOptions.find((unit) => unit.value === this.DEFAULT_DISTANCE_UNIT);
    }
  }

  getInitialSpeedUnitOpt(): { label: string; value: SpeedUnitType } {
    if (this.featureFlagsService.isFeatureEnabled(FeatureFlags.ChangeMetricUnits)) {
      return this.speedOptions.find((unit) => unit.value === this.speedUnit);
    } else {
      return this.speedOptions.find((unit) => unit.value === this.DEFAULT_SPEED_UNIT);
    }
  }
}
