import { DatabaseService } from './database.service';
import { EntityType, FilterOperation } from './worker/database.types';

export class DatabaseRepositoryBase<T> {
  constructor(private entityType: EntityType, private databaseService: DatabaseService) {}

  async add(item: T): Promise<T> {
    return (
      await this.databaseService.pushSync<T>({
        entityType: this.entityType,
        operation: 'add',
        data: item,
      })
    ).data;
  }

  async sync(query: FilterOperation<T>[] = []) {
    return (
      await this.databaseService.pushSync<number>({
        entityType: this.entityType,
        operation: 'sync',
        data: query,
      })
    ).data;
  }

  async delete(query: FilterOperation<T>[] = []) {
    return (
      await this.databaseService.pushSync<number>({
        entityType: this.entityType,
        operation: 'delete',
        data: query,
      })
    ).data;
  }

  async clear() {
    await this.databaseService.pushSync<T>({
      entityType: this.entityType,
      operation: 'clear',
    });
  }

  async count(query: FilterOperation<T>[] = []) {
    return (
      await this.databaseService.pushSync<number>({
        entityType: this.entityType,
        operation: 'count',
        data: query,
      })
    ).data;
  }
}
