import { Component, Directive, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { fadeInAnimation } from 'src/app/animations/fade.animation';
import { TAction, Toast } from './toast.model';

@Directive({
  selector: '[dynamicToastComponent]',
})
export class DynamicToastComponent {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

export enum Highlight {
  SUCCESS = 'success',
  WARN = 'warning',
  DANGER = 'danger',
  NORMAL = 'normal',
  INFO = 'info',
  IMPORTANT = 'important',
  STANDARD = 'standard',
}

@Component({
  selector: 'ya-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: {
    '[class]': 'highlight',
    '[@fadeInOut]': '',
  },
  animations: [fadeInAnimation('fadeInOut', 250)],
})
export class ToastComponent implements OnInit {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() highlight: string = Highlight.NORMAL;
  @Input() hasAction: boolean = false;
  @Input() actionIcon: string = '';
  @Input() actionText: string = '';
  @Input() action: TAction;
  @Input() dynamicComponent: any;
  @Input() dynamicComponentData: any;
  @Input() hasIcon: boolean = true;
  @Input() actionDelay: number = 0;
  @Input() timeout: number = 0;
  @Input() toast: Toast;

  isLoading = false;

  @Output() actionClicked: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(DynamicToastComponent, { static: true }) dynamicToastComponent!: DynamicToastComponent;

  ngOnInit(): void {
    this.loadDynamicComponent();
    if (this.actionDelay > 0) {
      this.hasAction = false;
      setTimeout(() => {
        this.hasAction = true;
      }, this.actionDelay);
    }
    if (this.timeout > 0) {
      setTimeout(() => {
        this.handleAction();
      }, this.timeout);
    }
  }

  async handleAction() {
    this.isLoading = true;
    if (this.action) {
      await this.action();
    }
    this.actionClicked.emit(true);
  }

  private loadDynamicComponent(): void {
    if (!this.dynamicComponent) {
      return;
    }
    const viewContainerRef = this.dynamicToastComponent.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(this.dynamicComponent);
    if (this.dynamicComponentData) {
      componentRef.setInput('data', this.dynamicComponentData);
    }
    componentRef.setInput('toast', this.toast);
  }
}

@Directive({
  selector: 'ya-toast-title',
  host: { class: 'toast-title' },
})
export class ToastTitle { }

@Directive({
  selector: 'ya-toast-message',
  host: { class: 'toast-message' },
})
export class ToastMessage { }

@Directive({
  selector: 'ya-toast-actions',
  host: { class: 'toast-actions' },
})
export class ToastActions { }
