import { Injectable } from '@angular/core';
import { filter, interval, lastValueFrom, map } from 'rxjs';
import { ApiService } from 'src/app/core/api/api.service';
import { Layer, LayerSourceType, Situation } from 'src/app/core/domain';
import { DashboardSituationContextService } from 'src/app/dashboard/dashboard-situation-context.service';
import { UsageRepository } from '../database/repositories/usage.respository';
import { SocketService } from '../socket/socket.service';
import { ClusterService } from './cluster.service';
import { PredefinedSite, PredefinedSiteLocation } from '../domain/view-site.domain';
import { ShipInfo } from 'src/app/ship-database/shipInfo.model';

export type UsageFunctionalityType =
  | 'select_vessel'
  | 'remove_vessel_interest'
  | 'add_vessel_interest'
  | 'swap_order'
  | 'play_loop'
  | 'play_video'
  | 'move_to_last_location'
  | 'move_to_location'
  | 'move_to_control_window_position'
  | 'zoom_in'
  | 'zoom_out'
  | 'create_object'
  | 'edit_object'
  | 'delete_object'
  | 'remove_object'
  | 'show_object'
  | 'hide_object'
  | 'upload_object'
  | 'detach_object'
  | 'download_object'
  | 'duplicate_object'
  | 'export_object'
  | 'move_object'
  | 'archive_object'
  | 'unarchive_object'
  | 'select_object'
  | 'unselect_object'
  | 'reply_message'
  | 'send_message'
  | 'read_message'
  | 'send_file'
  | 'expand_image'
  | 'open_situation'
  | 'open_draft_situation'
  | 'close_situation'
  | 'publish_situation'
  | 'archive_situation'
  | 'remove_archive_situation'
  | 'addtosituation'
  | 'removefromsituation'
  | 'open-situation-layer'
  | 'close-situation-layer'
  | 'addtolayerlibrary'
  | 'moveLayerlibraryFolder'
  | 'removefromlayerlibrary'
  | 'addtofavorites'
  | 'removefromfavorites'
  | 'set_distance'
  | 'set_speed'
  | 'set_language'
  | 'set_user_preferences'
  | 'select_tool'
  | 'unselect_tool'
  | 'user_login'
  | 'user_logout'
  | 'open_window'
  | 'close_window'
  | 'open_tab'
  | 'manage_sound_alerts'
  | 'manager_visual_alerts'
  | 'show_on_map'
  | 'hide_on_map'
  | 'fit_to_object_on_map'
  | 'add_filter_map'
  | 'remove_filter_map'
  | 'create_view_layer'
  | 'create_smart_layer'
  | 'clear_filters'
  | 'filter_by_area'
  | 'search_objects'
  | 'distance_measurement'
  | 'range_measurement'
  | 'create_comment'
  | 'create_event'
  | 'share_groups'
  | 'share_members'
  | 'choose_users'
  | 'situation_layers'
  | 'title_error_open'
  | 'title_error_close'
  | 'enable_translation'
  | 'disable_translation'
  | 'set_translation_language'
  | 'translate_message'
  | 'see_original'
  | 'complete_task'
  | 'incomplete_task'
  | 'open_layout_panel'
  | 'select_layout'
  | 'submit_layout'
  | 'select_track_prediction'
  | 'select_track_history'
  | 'select_menu'
  | 'follow_track_window'
  | 'view_selected_object'
  | 'remove_selected_object'
  | 'add_to_layer'
  | 'show_in_ship_database'
  | 'add_selection_to_layer'
  | 'create_view_layer_from_selection'
  | 'cancel_remove_object'
  | 'automatic_sync_situation'
  | 'recording'
  | 'fit_to_layer'
  | 'edit_data_set'
  | 'manage_object_history'
  | 'edit_properties_selection'
  | 'open_link';

export type UsageActionType =
  | 'read'
  | 'stop'
  | 'play'
  | 'move'
  | 'zoom'
  | 'detach'
  | 'archive'
  | 'send'
  | 'open'
  | 'close'
  | 'add'
  | 'edit'
  | 'remove'
  | 'search'
  | 'select'
  | 'unselect'
  | 'export'
  | 'share'
  | 'hide'
  | 'show'
  | 'download'
  | 'upload'
  | 'logout'
  | 'login'
  | 'activate'
  | 'inactivate'
  | 'translate'
  | 'submit'
  | 'fit'
  | 'cancel';

export type UsageModuleType =
  | 'ship_database'
  | 'log'
  | 'situations'
  | 'map'
  | 'general'
  | 'message_board'
  | 'workbench'
  | 'notifications'
  | 'layer_repository'
  | 'chat'
  | 'global_chat'
  | 'to_do'
  | 'situation_room_display';

export type UsageComponentType =
  | 'tile_error'
  | 'ship-data-base'
  | 'map_screenshot'
  | 'loud_speaker'
  | 'view_sites'
  | 'dashboard'
  | 'board_message_form'
  | 'board_message_list'
  | 'layer_options'
  | 'layer_library'
  | 'situation_menu'
  | 'situation_details'
  | 'log_list'
  | 'chat'
  | 'map_tools'
  | 'work_bench'
  | 'work_bench_menu'
  | 'work_bench_details'
  | 'work_bench_advanced_search'
  | 'advanced_search_objects'
  | 'session_settings'
  | 'user_preferences'
  | 'board_message_share'
  | 'advanced_filter'
  | 'measure_menu'
  | 'manage_incidents'
  | 'create_incident_form'
  | 'manage_cpas'
  | 'edit_cpa_form'
  | 'daily_reports'
  | 'center_status'
  | 'user_menu_component'
  | 'home_component'
  | 'debug_console'
  | 'notifications'
  | 'todo_list'
  | 'todo_form'
  | 'control_window'
  | 'ship-details'
  | 'skylight_details'
  | 'situation_room_display'
  | 'situation_room_display_form'
  | 'map_actions'
  | 'selected_objects'
  | 'edit_data_set'
  | 'edit_history_set';

export type ExtraDataType = { [key: string]: string | object | boolean };

export enum SettingType {
  Language = 'Language',
  SpeedUnit = 'SpeedUnit',
  DistanceUnit = 'DistanceUnit',
  BoardMessageForm = 'Board Message Form',
}

export enum WindowOpenTypes {
  AddOtherLayers = 'Add Other Layers',
  LayerOptions = 'Layer Options',
  CreateLayer = 'Create Layer',
  CreateSmartLayer = 'Create Smart Layer',
  MapTools = 'Map Tools',
  PointForm = 'Point Form',
  TextForm = 'Text Form',
  LineForm = 'Line Form',
  CircleForm = 'Circle Form',
  PolygonForm = 'Polygon Form',
  VideoForm = 'Video Form',
  ImageForm = 'Image Form',
  SelectImageForm = 'Select Image Form',
  AdvancedSearch = 'Advanced Search',
  SessionSettings = 'Session Settings',
  UserPreferences = 'User Preferences',
  BoardMessageForm = 'Board Message Form',
  MessageBoardShare = 'Message Board Share',
  BoardMessageList = 'Board Message List',
  WorkBenchMyDrafts = 'My Drafts',
  WorkbenchWindow = 'Workbench',
  WorkBenchDetail = 'Workbench detail',
  WorkBenchSharedWithMe = 'Shared With Me',
  WorkBenchShareMembers = 'Share Members',
  WorkBenchShareCenters = 'Share Centers',
  AdvanceSearchObject = 'Advance Search Object',
  AdvanceFilter = 'Advance Filter',
  AdvanceFilterQuerie = 'Advance Filter Querie',
  MeasureMenu = 'Measure Menu',
  ManageIncidents = 'Manage Incidents',
  IncidentsForm = 'Incidents Form',
  ChatForm = 'Chat Form',
  ManageCPAs = 'Manage CPAs',
  DailyReports = 'Daily Reports',
  CenterStatus = 'Center Status',
  HomePage = 'Home Page',
  UserMenu = 'User Menu',
  DebugConsole = 'Debug Console',
  Notifications = 'Notifications',
  DirexLoudSpeaker = 'Direx Loud Speaker',
  SituationDetail = 'Situation detail',
  Situations = 'Situations',
  WatchingSituations = 'Watching Situations',
  SituationDocuments = 'Situation Documents',
  LogList = 'Log List',
  LogComments = 'Log Comments',
  ChatList = 'Chat List',
  Chat = 'Chat',
  GlobalChat = 'Global Chat',
  ScreenShot = 'Screen Shot',
  ViewSitesMenu = 'View Sites Menu',
  LoudSpeakerWhoViewsList = 'List Who View the Loud Speaker',
  ShipDataBase = 'Ship Data Base',
  MembersList = 'Members List',
  ListResults = 'List Results',
  TodoList = 'Todo List',
  TodoForm = 'Todo Form',
  SituationRoomDisplay = 'Situation Room Display',
  SituationRoomDisplayForm = 'Situation Room Display Form',
  ControlWindow = 'Contro lWindow',
  ShipDetails = 'Ship Details',
  SkylightDetails = 'Skylight Details',
  MapActionsMenu = 'Map Actions Menu',
  SelectedObjects = 'Selected Objects',
  MSAObjectProperties = 'MSAObject Properties',
  MSAObjectDataSet = 'Edit Data Set',
  MSAObjectHistoryDataSet = 'History Data Set',
}
@Injectable({
  providedIn: 'root',
})
export class UsageService {
  syncInterval$ = interval(1000 * 5);
  syncRunning = false;

  constructor(
    private usageRepository: UsageRepository,
    public readonly situationContext: DashboardSituationContextService,
    private readonly socketService: SocketService, // getting user without circular dependency
    private clusterService: ClusterService,
    private api: ApiService,
  ) {
    this.syncByInterval();
  }

  async capture(usage: {
    functionality: UsageFunctionalityType;
    action: UsageActionType;
    module: UsageModuleType;
    component: UsageComponentType;
    metadata?: Record<string, string>;
  }) {
    this.usageRepository.add({
      timestamp: new Date(),
      app: 'hmi',
      cluster: this.situationContext.getEditMode(),
      clusters: [this.clusterService.getInitialCluster()],
      functionality: usage.functionality,
      module: usage.module,
      component: usage.component,
      action: usage.action,
      metadata: usage.metadata,
      user: this.socketService.getUser()._id,
    });
  }

  async syncByInterval() {
    this.syncInterval$.pipe(filter(() => !this.syncRunning)).subscribe(() => {
      this.syncByCount();
    });
  }

  async syncByCount() {
    const count = await this.usageRepository.count();
    if (count >= 10) {
      console.log('Sync usage by count...');
      this.syncRunning = true;
      await this.sync();
      this.syncRunning = false;
    }
  }

  /**
   * Remove indexeddb data from another users on login
   */
  async clearUsersData() {
    await this.usageRepository.delete([{ prop: 'user', type: 'neq', value: this.socketService.getUser()._id }]);
  }

  async sync() {
    const syncCount = await this.usageRepository.sync([
      { prop: 'user', type: 'eq', value: this.socketService.getUser()._id },
    ]);
    console.log('Usage Sync count: ', syncCount);
  }

  getUser() {
    return this.socketService.getUser();
  }

  async getUserMetadata() {
    const currentUser = this.getUser();
    const userIp = await lastValueFrom(this.api.userGroup.getCurrentIP().pipe(map((data) => data.userIP)));
    return {
      Username: currentUser.Username,
      UserGroup: currentUser.GroupName,
      UserCountryCode: currentUser.Group.CountryCode,
      UserGroupLocation: currentUser.Group.DirectoryPath?.match(/^\/[^/]*\/([^/]*)\//)?.[1] || '',
      UserIp: userIp,
    };
  }

  async fetchAndProcessUsersDataById(usersIds: string[]) {
    const usernames = await lastValueFrom(this.api.userGroup.getUsernamesList(usersIds));
    return usernames.map((user) => user.Username).join(', ');
  }

  async getCurrentSituationName() {
    return { SituationName: this.situationContext.getData().Name };
  }

  async getLayerNameById(_id: any) {
    const layerData = await lastValueFrom(this.api.layers.getLayerName(_id));
    return layerData?.data?.Name;
  }

  async captureLayerUsage(
    layer: Layer,
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    component: UsageComponentType,
    isLayerVisible: boolean,
    extraData: any = {},
    overwriteSituation?: Situation,
  ) {
    try {
      const situation = overwriteSituation ?? this.situationContext.getData();

      let layerSourceType: string = '';
      if (layer?.Provider.length > 0) {
        if (layer?.SourceType === LayerSourceType.SKYLIGHT)
          layerSourceType = layer.Provider[0].replace('SKYLIGHT:', '');
        else layerSourceType = layer?.Provider[0];
      }

      let layerStatus = 'Inactive';

      if (
        action === 'open' ||
        (action === 'add' && functionality === 'addtofavorites') ||
        (action === 'remove' && functionality === 'removefromfavorites') ||
        (action === 'remove' && functionality === 'removefromlayerlibrary') ||
        (action === 'add' && component === 'layer_options') ||
        functionality === 'addtosituation' ||
        action === 'show' ||
        action === 'share' ||
        action === 'export' ||
        action === 'edit'
      ) {
        layerStatus = isLayerVisible ? 'Active' : 'Inactive';
      } else if (
        (action === 'remove' && functionality !== 'removefromfavorites') ||
        functionality === 'removefromsituation' ||
        !isLayerVisible ||
        action === 'hide'
      ) {
        layerStatus = 'Inactive';
      }

      const metadata = {
        ...extraData,
        ...(await this.getUserMetadata()),
        SituationName: situation.Name,
      };

      if (functionality !== 'open_window' && functionality !== 'close_window') {
        metadata.Status = layerStatus;
      }

      if (layer) {
        metadata.LayerName = layer.Name;
        metadata.LayerType = layer.LayerType;
        metadata.LayerSourceType = layerSourceType;
      } else {
        metadata.WindowOpen = component;
      }

      this.capture({
        functionality: functionality,
        action: action,
        module: 'layer_repository',
        component: component,
        metadata: metadata,
      });
    } catch (error) {
      console.error('Error sending layer usage', error);
    }
  }

  async captureSettingsMenuUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    settingChanges?: { settingType: SettingType; settingValue: string },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.SessionSettings;

    if (action === 'edit') {
      switch (settingChanges?.settingType) {
        case SettingType.Language:
          metadata.Language = settingChanges?.settingValue;
          break;
        case SettingType.SpeedUnit:
          metadata.SpeedUnit = settingChanges?.settingValue;
          break;
        case SettingType.DistanceUnit:
          metadata.DistanceUnit = settingChanges?.settingValue;
          break;
      }
    }

    this.capture({
      action: action,
      component: 'session_settings',
      module: 'map',
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureUserPreferencesUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    userPreferencesObject?: any,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = WindowOpenTypes.UserPreferences;

    if (action === 'edit') {
      metadata.CoordinatesFormat = userPreferencesObject.CoordinatesFormat;
      metadata.DistanceUnit = userPreferencesObject.DistanceUnit;
      metadata.SpeedUnit = userPreferencesObject.SpeedUnit;
      metadata.Language = userPreferencesObject.Language;
    }

    this.capture({
      action: action,
      component: 'user_preferences',
      module: 'general',
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureMessageBoardUsage(
    action: UsageActionType,
    component: UsageComponentType,
    functionality: UsageFunctionalityType,
    meesageBoardData?: { [key: string]: string },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = meesageBoardData?.windowOpen;

    this.capture({
      action: action,
      component: component,
      functionality: functionality,
      module: 'message_board',
      metadata: metadata,
    });
  }

  async captureWorkbenchUsage(
    action: UsageActionType,
    component: UsageComponentType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | string[] },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    if (extraData) {
      metadata.WindowOpen = extraData?.windowOpen;
      metadata.ExportAs = extraData?.exportAs;
      metadata.DraftSituationName = extraData?.draftName;
      metadata.SharingGroup = extraData?.sharingGroups;
      metadata.SearchQuery = extraData?.searchQuery;
      if (extraData?.membersShared && Array.isArray(extraData.membersShared)) {
        metadata.MembersShared = await this.fetchAndProcessUsersDataById(extraData.membersShared);
      }
    }

    this.capture({
      action: action,
      component: component,
      functionality: functionality,
      module: 'workbench',
      metadata: metadata,
    });
  }

  async captureAdvanceSearchObjectsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = WindowOpenTypes.AdvanceSearchObject;
    metadata.FilterByArea = extraData?.searchByArea;
    metadata.SearchOptions = extraData?.searchOptions;

    this.capture({
      action: action,
      component: 'advanced_search_objects',
      functionality: functionality,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureAdvanceFilterUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };
    metadata.WindowOpen = extraData?.windowOpen ? extraData.windowOpen : WindowOpenTypes.AdvanceFilter;

    this.capture({
      action: action,
      component: 'advanced_filter',
      functionality: functionality,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureMeasureMenuUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };
    metadata.WindowOpen = WindowOpenTypes.MeasureMenu;
    metadata.ExportedFile = extraData?.FileName;

    this.capture({
      action: action,
      component: 'measure_menu',
      functionality: functionality,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureManageIncidents(
    action: UsageActionType,
    component: UsageComponentType,
    functionality: UsageFunctionalityType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen =
      component === 'manage_incidents' ? WindowOpenTypes.ManageIncidents : WindowOpenTypes.IncidentsForm;

    this.capture({
      action: action,
      component: component,
      functionality: functionality,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureManageCPAs(
    action: UsageActionType,
    component: UsageComponentType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.ManageCPAs;
    metadata.RefreshTimeInSec = extraData?.RefreshTimeInSec;

    this.capture({
      action: action,
      component: component,
      functionality: functionality,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureDailyReportsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = WindowOpenTypes.DailyReports;
    metadata.ExportedFile = extraData?.Filename;

    this.capture({
      action: action,
      component: 'daily_reports',
      functionality: functionality,
      module: 'general',
      metadata: metadata,
    });
  }

  async captureCenterStatusUsage(action: UsageActionType, functionality: UsageFunctionalityType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = WindowOpenTypes.CenterStatus;

    this.capture({
      action: action,
      component: 'center_status',
      functionality: functionality,
      module: 'general',
      metadata: metadata,
    });
  }

  async captureGeneralUserMenuUsage(action: UsageActionType, functionality: UsageFunctionalityType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };
    metadata.WindowOpen = action === 'login' ? WindowOpenTypes.HomePage : WindowOpenTypes.UserMenu;

    this.capture({
      action: action,
      component: action === 'login' ? 'home_component' : 'user_menu_component',
      functionality: functionality,
      module: 'general',
      metadata: metadata,
    });
  }

  async captureDebugConsoleUsage(action: UsageActionType, functionality: UsageFunctionalityType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };
    metadata.WindowOpen = WindowOpenTypes.DebugConsole;

    this.capture({
      action: action,
      component: 'debug_console',
      functionality: functionality,
      module: 'general',
      metadata: metadata,
    });
  }

  async captureLayerCertErrorUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    tile: string,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
      tile,
    };

    metadata.LayerName = await this.getLayerNameById(extraData?.layerId);

    await this.capture({
      action: action,
      component: 'tile_error',
      functionality: functionality,
      module: 'layer_repository',
      metadata: metadata,
    });
  }

  async captureNotificationUsageService(
    action: UsageActionType,
    component: UsageComponentType,
    module: UsageModuleType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = component.includes('notifications')
      ? WindowOpenTypes.Notifications
      : WindowOpenTypes.DirexLoudSpeaker;
    metadata.Tabs = { PreviousTab: extraData?.PreviousTab, CurrentTab: extraData?.CurrentTab };

    this.capture({
      action: action,
      component: component,
      module: module,
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureSituationUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: { [key: string]: string | object },
    component?: UsageComponentType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    if (extraData) {
      metadata.SituationName = extraData?.situationName;
      metadata.ShareGroups = JSON.stringify(extraData?.sharegroups);
      metadata.WindowOpen = extraData?.windowopen;
    }

    const usageComponent = component || 'situation_menu';

    this.capture({
      action: action,
      component: usageComponent,
      module: 'situations',
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureLogUsage(action: UsageActionType, functionality: UsageFunctionalityType, extraData?: ExtraDataType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = extraData?.windowOpen || WindowOpenTypes.LogList;

    if (extraData) {
      metadata.ShareGroups = JSON.stringify(extraData?.sharegroups);
      metadata.IsHistory = extraData?.History;
      metadata.SensitivityLevel = extraData?.SensitivityLevel;
      metadata.Security = extraData?.Security;
      metadata.EventType = extraData?.EventType;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'log_list',
      module: 'log',
      metadata: metadata,
    });
  }

  async captureChatUsage(action: UsageActionType, functionality: UsageFunctionalityType, extraData?: ExtraDataType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = extraData?.isGlobalChat ? WindowOpenTypes.GlobalChat : WindowOpenTypes.ChatList;

    if (extraData) {
      metadata.FileName = extraData?.FileName;
      if (extraData?.chatOpen && (!extraData?.isGlobalChat || extraData?.isGlobalChat === undefined)) {
        metadata.WindowOpen = 'Chat ' + extraData?.chatOpen;
      } else if (extraData?.windowOpen) {
        metadata.WindowOpen = extraData?.windowOpen;
      } else {
        metadata.WindowOpen = extraData?.isGlobalChat ? WindowOpenTypes.GlobalChat : WindowOpenTypes.ChatList;
      }
      metadata.Chat = extraData?.chatName;
      metadata.userTracker = extraData?.userTracker;
      if (extraData?.usersIds && Array.isArray(extraData?.usersIds)) {
        metadata.MembersShared = await this.fetchAndProcessUsersDataById(extraData.usersIds);
      }
      metadata.TranslationLanguage = extraData?.translationLanguage;
      metadata.Selection = extraData?.Selection
    }

    this.capture({
      action: action,
      component: 'chat',
      module: extraData?.isGlobalChat ? 'global_chat' : 'chat',
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureToolsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    ToolData: { ToolType?: string; ObjectInfo?: string } = {},
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
      ToolType: ToolData?.ToolType,
      ObjectInfo: ToolData?.ObjectInfo,
    };

    metadata.WindowOpen = extraData?.windowOpen ? extraData.windowOpen : WindowOpenTypes.MapTools;

    if (extraData) {
      metadata.PointType = extraData?.PointType;
      if (extraData?.LayerName) {
        metadata.LayerName = extraData?.LayerName;
      } else if (extraData?.layerId) {
        metadata.LayerName = await this.getLayerNameById(extraData?.layerId);
      }
      metadata.ExportedAs = extraData?.ExportedAs;
    }

    this.capture({
      action: action,
      component: 'map_tools',
      module: 'map',
      functionality: functionality,
      metadata: metadata,
    });
  }

  async captureDashBoardUsage(action: UsageActionType, functionality: UsageFunctionalityType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    this.capture({
      action: action,
      functionality: functionality,
      component: 'dashboard',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureScreenShotUsage(action: UsageActionType, functionality: UsageFunctionalityType) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.ScreenShot;

    this.capture({
      action: action,
      functionality: functionality,
      component: 'map_screenshot',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureViewSitesUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    viewSiteDetails?: { predefinedSite?: PredefinedSite; positionDetail?: PredefinedSiteLocation },
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    if (extraData) {
      metadata.WindowOpen = extraData?.openWindow;
    }

    if (viewSiteDetails && action !== 'open' && action !== 'play') {
      const predefinedSite = viewSiteDetails.predefinedSite;
      const positionDetail = viewSiteDetails.positionDetail;

      if (functionality === 'swap_order') {
        const siteNumber = predefinedSite?.siteNumber;
        metadata.NewSiteNumber = (siteNumber + 1).toString();
        metadata.LastSiteNumber = siteNumber?.toString();
      } else {
        metadata.SiteNumber = predefinedSite?.siteNumber?.toString();
      }

      metadata.SiteName = predefinedSite?.siteName;

      const siteLocation = predefinedSite?.predefinedSiteLocation || positionDetail;
      metadata.SiteCoordinates = siteLocation?.coordinates.toString();
      metadata.SiteZoom = siteLocation?.zoom.toString();
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'view_sites',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureLoudSpeakerUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    if (extraData) {
      metadata.LoudSpeakerId = extraData?.loudspeaker;
      metadata.WindowOpen = extraData?.windowOpen;
      metadata.MessageType = extraData?.messageType;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'loud_speaker',
      module: 'notifications',
      metadata: metadata,
    });
  }

  async captureShipDataBaseUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    vesselData?: ShipInfo,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = WindowOpenTypes.ShipDataBase;

    if (extraData || vesselData) {
      metadata.OpenTab = extraData?.tab;
      metadata.Source = extraData?.source;
      metadata.VesselName = vesselData?.name;
      metadata.VesselIMO = vesselData?.imoNumber;
      metadata.VesselMMSI = vesselData?.mmsi;
      metadata.File = extraData?.fileName;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'ship-data-base',
      module: 'ship_database',
      metadata: metadata,
    });
  }

  async captureShipDetailsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.ShipDetails;

    if (extraData) {
      metadata.WindowOpen = extraData?.windowOpen;
      metadata.VesselName = extraData?.name;
      metadata.VesselIMO = extraData?.imoNumber;
      metadata.VesselMMSI = extraData?.mmsi;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'ship-details',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureToDoUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    component: UsageComponentType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
    };

    metadata.WindowOpen = component === 'todo_form' ? WindowOpenTypes.TodoForm : WindowOpenTypes.TodoList;
    if (extraData) {
      if (extraData?.tab && typeof extraData?.tab === 'string') {
        const tabKey = extraData?.tab.split('.').pop();
        metadata.OpenTab = tabKey.charAt(0) + tabKey.slice(1).toLowerCase();
      }
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: component,
      module: 'to_do',
      metadata: metadata,
    });
  }

  async captureSituationRoomDisplayUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    component: UsageComponentType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    if (extraData) {
      metadata.Layout = extraData?.layout;
      metadata.WidgetsSelected = extraData?.widgets;
    }
    metadata.WindowOpen = extraData?.windowOpen ?? WindowOpenTypes.SituationRoomDisplay;
    this.capture({
      action: action,
      functionality: functionality,
      component: component,
      module: 'situation_room_display',
      metadata: metadata,
    });
  }

  async captureControlWindowUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
    component?: UsageComponentType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.ControlWindow;
    const usageComponent = component || 'control_window';

    if (extraData) {
      metadata.VesselName = extraData?.vesselName;
      metadata.VesselIMO = extraData?.vesselImo;
      metadata.VesselMMSI = extraData?.vesselMmsi;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: usageComponent,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureMapActionsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.MapActionsMenu;

    if (extraData) {
      metadata.WindowOpen = extraData?.windowOpen;
      metadata.Selection = extraData?.selection;
      metadata.MsaObjectName = extraData?.msaObjectName;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'map_actions',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureSelectedObjectsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.SelectedObjects;

    if (extraData) {
      metadata.VesselName = extraData?.vesselName;
      metadata.VesselIMO = extraData?.vesselIMO;
      metadata.VesselMMSI = extraData?.vesselMMSI;
      metadata.LayerName = extraData?.layerName;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'selected_objects',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureEditDataSetUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.MSAObjectDataSet;

    if (extraData) {
      metadata.ObjectName = extraData?.ObjectName;
      metadata.Selection = extraData?.Selection;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'edit_data_set',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureHistoryDataSetUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.MSAObjectHistoryDataSet;

    if (extraData) {
      metadata.ObjectName = extraData?.ObjectName;
      metadata.Selection = extraData?.Selection;
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'edit_history_set',
      module: 'map',
      metadata: metadata,
    });
  }

  async captureDataTableUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.MSAObjectDataSet;

    if (extraData) {
      metadata.ObjectName = extraData?.ObjectName;
      metadata.WindowOpen = extraData?.windowOpen;
      metadata.Selection = extraData?.Selection;
    }

    const component = metadata.WindowOpen == WindowOpenTypes.MSAObjectDataSet ? 'edit_data_set' : 'edit_history_set';

    this.capture({
      action: action,
      functionality: functionality,
      component: component,
      module: 'map',
      metadata: metadata,
    });
  }

  async captureSkylightDetailsUsage(
    action: UsageActionType,
    functionality: UsageFunctionalityType,
    extraData?: ExtraDataType,
  ) {
    const metadata: any = {
      ...(await this.getUserMetadata()),
      ...(await this.getCurrentSituationName()),
    };

    metadata.WindowOpen = WindowOpenTypes.SkylightDetails;

    if (extraData) {
      metadata.WindowOpen = extraData?.windowOpen ?? WindowOpenTypes.SkylightDetails;
      metadata.SkylightType = extraData?.skylightType;
      metadata.Selection = extraData?.Selection;
      if (extraData?.layerId) {
        metadata.LayerName = await this.getLayerNameById(extraData?.layerId);
      }
    }

    this.capture({
      action: action,
      functionality: functionality,
      component: 'skylight_details',
      module: 'map',
      metadata: metadata,
    });
  }
}
