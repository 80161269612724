import { FolderGroup, SecurityType, SensitivityLevel, SharingGroup, SharingGroupRule, ToggleSharingLayers } from './core.domain';
import { UserSummary } from './user-group.domain';

export enum SituationActionType {
  Shared = 'Shared',
  Created = 'Created',
  Updated = 'Updated',
}

export enum SituationEnvironmentEnum {
  Live = 'LIV',
  Training = 'TRN',
  Exercise = 'EXR',
  NotLive = 'NOT_LIV',
}

export enum SituationPriority {
  VeryHigh = 600,
  High = 500,
  Medium = 400,
  Low = 300,
  VeryLow = 200,
  None = 100,
}

export enum SituationProperties {
  Name = 'Name',
  Tags = 'Tags',
  Security = 'Level',
  SensitivityLevel = 'Sensitivity',
  Description = 'Description',
}

export enum SituationStateType {
  Draft = 'draft',
  Published = 'published',
}

export interface SituationStateBase {
  Type: SituationStateType;
}

export interface Draft extends SituationStateBase {
  Type: SituationStateType.Draft;
  SharingGroupRules: SharingGroupRule[];
}

export interface Published extends SituationStateBase {
  Type: SituationStateType.Published;
}

type SituationState = Draft | Published;

export interface Situation {
  _id: string;
  Name: string;
  Description: string;
  Layers_ids: string[];
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt: string;
  UpdatedAt: string;
  IsActive: boolean;
  Tags: string[];
  IsDefault?: boolean;
  CreatedBy?: UserSummary;
  Owner?: string;
  SharingGroups: SharingGroup[];
  SharingGroupRules: SharingGroupRule[];
  ShareWithOutsideYA: boolean;
  Priority: SituationPriority;
  FavoritedByGroupNames?: string[];
  Folders?: FolderGroup[];
  Environment?: SituationEnvironmentEnum;
  State: SituationState;
  ToggleSharingLayers?: ToggleSharingLayers[];
}
