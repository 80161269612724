import { Highlight } from './toast.component';

export type TAction = () => Promise<unknown>;

export class Toast {
  key?: string;
  title: string;
  message: string;
  highlight: Highlight | string;
  hasAction: boolean;
  actionIcon: string;
  actionText: string;
  action: TAction;
  component: any;
  data: any;
  actionDelay: number;
  timeout: number;
  type: 'notification' | 'system';
  hasIcon: boolean;

  constructor(options: Partial<Toast>) {
    this.key = options.key || '';
    this.title = options.title || '';
    this.message = options.message || '';
    this.highlight = options.highlight || Highlight.NORMAL;
    this.hasAction = options.hasAction || false;
    this.actionIcon = options.actionIcon || '';
    this.actionText = options.actionText || '';
    this.action = options.action;
    this.component = options.component || null;
    this.data = options.data || null;
    this.actionDelay = options.actionDelay || 0;
    this.timeout = options.timeout || 0;
    this.type = options.type || 'notification';
    this.hasIcon = options.hasIcon ?? true;
  }
}
